<template>
  <div class="work-bench-right">
    <div class="bench-content-wrapper" v-if="showBench">
      <div class="bench-head">
        <div class="bench-head-avatar">
          <img :src="mineInfo.avatar" alt="">
        </div>
        <div class="bench-head-info">
          <p  class="name">
            <span class="text-overflow">{{mineInfo.nickname}}</span>
            <i v-if="routeName === 'studentDataDetails'" class="iconfont" :class="{isUnfold :  selectAccountShow}" @click="selectAccountShow = !selectAccountShow">&#xe771;</i>
            <i v-else></i>
          </p>
          <!--                    <p class="account">抖音号：{{mineInfo.unique_id}}</p>-->
          <p class="info">
            <span v-if="mineInfo.gender">性别：{{mineInfo.gender==0?'未知':mineInfo.gender==1?'男':'女'}}</span>
            <span v-if="mineInfo.birthday">生日：{{mineInfo.birthday}}</span>
          </p>
        </div>
        <div class="account-list-box" v-if="selectAccountShow">
          <el-scrollbar class="account-list">
            <div class="account-item" v-for="item in shortList" @click="selectAccountClick(item)" :class="{itemActive: activeId == item.id}">
              <div class="account-item-avatar">
                <img :src="item.avatar" alt="">
              </div>
              <span class="text-overflow">{{item.nickname}}</span>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <!--            <div class="bench-summary">-->
      <!--                <div class="summary-left">简介：</div>-->
      <!--                <div class="summary-content">{{mineInfo.signature}}</div>-->
      <!--            </div>-->
      <div class="bench-summary">
        <div class="summary-left">地区：</div>
        <div class="summary-content">{{mineInfo.country}}{{mineInfo.province ? mineInfo.province + '省' : mineInfo.province}}{{mineInfo.city ? mineInfo.city + '市' : mineInfo.city}}</div>
      </div>
      <div class="bench-data-overview">
        <div class="data-title">数据概况</div>
        <div class="data-list">
          <div class="data-item">
            <div class="item-left">
              <span class="item-hang1">粉丝数量</span>
              <span class="item-num big-green" v-if="mineInfo.follower_count">{{mineInfo.follower_count}}</span>
              <span class="item-num" v-else>0</span>
            </div>
            <div class="item-right">
              <span class="item-hang1">作品数</span>
              <span class="item-num" v-if="mineInfo.aweme_count">{{mineInfo.aweme_count}}</span>
              <span class="item-num" v-else>0</span>
            </div>
          </div>
          <div class="data-item">
            <div class="item-left">
              <span class="item-hang1">总点赞数</span>
              <span class="item-num" v-if="mineInfo.favoriting_count">{{mineInfo.favoriting_count}}</span>
              <span class="item-num" v-else>0</span>
            </div>
            <div class="item-right">
              <span class="item-hang1">总评论量</span>
              <span class="item-num" v-if="mineInfo.comment_count">{{mineInfo.comment_count}}</span>
              <span class="item-num" v-else>0</span>
            </div>
          </div>
          <div class="data-item">
            <div class="item-left">
              <span class="item-hang1">总转发量</span>
              <span class="item-num" v-if="mineInfo.forward_count">{{mineInfo.forward_count}}</span>
              <span class="item-num" v-else>0</span>
            </div>
            <div class="item-right">
              <span class="item-hang1">总播放量</span>
              <span class="item-num" v-if="mineInfo.play_count">{{mineInfo.play_count}}</span>
              <span class="item-num" v-else>0</span>
            </div>
          </div>
<!--          <div class="data-item">-->
<!--            <div class="item-left">-->
<!--              <span class="item-num">完成任务</span>-->
<!--            </div>-->
<!--            <div class="item-right">-->
<!--              <span class="item-num" @click="toFinishTask">查看详情 ></span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="data-item">-->
<!--            <div class="item-left">-->
<!--              <span class="item-hang1">教师任务</span>-->
<!--              <span class="item-num">63</span>-->
<!--            </div>-->
<!--            <div class="item-right">-->
<!--              <span class="item-hang1">企业任务</span>-->
<!--              <span class="item-num">120</span>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <!--            <div class="bench-data-oper">-->
      <!--                <div class="btn-air-32" v-if="bid > 0" @click="toList()">切换账号</div>-->
      <!--            </div>-->
    </div>
    <div class="bench-nodata-wrapper" v-else>
      <div class="bench-nodata-img">
        <img src="../../assets/img/image/img_wuzhanghao.png" alt="">
      </div>
      <p class="bench-nodata-text">{{approvalStatus != ''?approvalStatus:"您还没有账号，快去添加吧"}}</p>
      <div class="btn-grablue-32" v-if="approvalStatus == ''" @click="addAccountWork()">
        <i class="iconfont">&#xe60b;</i>添加账号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrillAccountModule",
  data() {
    return {
      bid: 0,
      showBench: false,
      showCurrentBtn: true,
      // isShow: false,
      approvalStatus: '',//审核状态
      mineInfo: {},
      selectAccountShow:false,
      shortList:[],
      activeId:this.$route.query.id || '',
      routeName:this.$route.name || '',
    }
  },
  mounted() {
    this.getAccount();
    //获取抖音置顶账号信息
    // this.$httpStudent.axiosGet(this.$api.tikhoknew_manage_index, res => {
    //     if (res.code === 200) {
    //         this.bid =res.data.id
    //         this.mineInfo = res.data;
    //         // 显示用户基本信息
    //         this.showBench = true;
    //     } else if (res.code === 204) {
    //         // 保存审核状态
    //         this.approvalStatus = res.message;
    //         this.showBench = false;
    //     } else if (res.code === 400) {
    //         this.approvalStatus = res.message;
    //         // 隐藏用户基本信息
    //         this.showBench = false;
    //         if (res.message ==='当前账号不存在！'){
    //             this.approvalStatus = ''
    //         }
    //     }
    //     else {
    //         this.showBench = false;
    //         this.$message({
    //             type: "error",
    //             message: res.message,
    //             duration: 1000
    //         });
    //     }
    // })
  },
  methods: {
    getAccount(){
      let param = {
        type:1,
        platform_type:1
      };
      this.$httpStudent.axiosGetBy(this.$api.get_account, param, res => {
        if (res.code === 200) {
          this.shortList = res.data.data;
        } else if (res.code === 204) {
          this.$message.error(res.message);
          this.$router.push('/');
        }
      }, (err) => {
        console.log(err);
      })
    },
    selectAccountClick(item){
      const query = JSON.parse(JSON.stringify(this.$route.query));
      query.id = item.id;
      this.$router.push({path:this.$route.path,query})
      // this.$route.query.id = item.id;
      this.activeId = item.id;
      this.getAccountMine(item.type, item.platform_type, item.id);
      this.$emit('selectAccountUpdate', item.id);
      this.selectAccountShow = false;
    },
    addAccountWork() {
      this.$router.push('/student/account/index')
    },
    toList() {
      this.$router.push('/student/account/index')
    },
    getAccountMine(type,platform_type,id){
      let param = {
        type:type,
        platform_type:platform_type,
        id:id
      }
      this.$httpStudent.axiosGetBy(this.$api.get_account,param, res => {
        if (res.code === 200) {
          this.bid =res.data.id
          this.mineInfo = res.data;
          // 显示用户基本信息
          this.showBench = true;
        } else if (res.code === 204) {
          // 保存审核状态
          this.approvalStatus = res.message;
          this.showBench = false;
        } else if (res.code === 400) {
          this.approvalStatus = res.message;
          // 隐藏用户基本信息
          this.showBench = false;
          if (res.message ==='当前账号不存在！'){
            this.approvalStatus = ''
          }
        }
        else {
          this.showBench = false;
          this.$message({
            type: "error",
            message: res.message,
            duration: 1000
          });
        }
      })
    },
    toFinishTask(){
      this.$router.push({
        path:'/student/data/finishTask'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.work-bench-right {
  width: 360px;
  background: #fff;
  border-radius: 10px;
  margin-left: 20px;
  .bench-content-wrapper {
    padding: 30px;
    .bench-head {
      display: flex;
      align-items: center;
      position: relative;
      .account-list-box{
        width: 290px;
        position: absolute;
        left: 0;
        top: 114px;
        background: url("./../../assets/img/image/account-triangle-bj.png") no-repeat;
        background-size: 100% 100%;
        padding: 16px 10px;
        overflow: hidden;
        box-sizing: border-box;
      }
      .account-list{
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        height: 170px;
        ::v-deep.el-scrollbar__wrap {
          overflow-x: hidden;
        }
        .account-item{
          padding: 0 10px;
          line-height: 40px;
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover{
            background: #F1F0FE;
            color: #5B51EF;
          }
          .account-item-avatar{
            width: 27px;
            height: 27px;
            border-radius: 50%;
            overflow:hidden;
            margin-right: 8px;
            img{
              max-height: 100%;
              max-width: 100%;
            }
          }
        }
        .itemActive{
          background: #F1F0FE;
          color: #5B51EF;
        }
      }
    }
    .bench-head-avatar {
      width: 90px;
      height: 90px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .bench-head-info {
      width: 1%;
      flex: 1;
      line-height: 1;
      margin-left: 15px;
      color: #999;
      font-size: 14px;
      .name {
        color: #333;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        i{
          color: #333;
          font-size: 10px;
          cursor: pointer;
          transition: 0.3s;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .isUnfold{
          transform: rotate(180deg);
        }
        //&:hover {
        //  color: #20C997;
        //}
      }
      .account {
        margin-top: 10px;
      }
      .info {
        margin-top: 10px;
        span {
          display: inline-block;
          margin-right: 6px;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }

    }
    .bench-summary {
      margin-top: 20px;
      display: flex;
      .summary-content {
        width: 1%;
        flex: 1;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .bench-ranking {
      margin-top: 20px;
      .ranking-item {
        display: inline-flex;
        align-items: center;
        background: #FF1616;
        color: #fff;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        padding: 0 15px 0 2px;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
          background: #FFB624;
        }
        .rank-icon {
          width: 20px;
          height: 20px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          overflow: hidden;
          background: #fff url("../../assets/img/image/wb-hg-icon.png") no-repeat center;
        }
        .text {
          margin-left: 10px;
          .num {
            margin: 0 4px;
          }

        }
      }
    }
    .bench-data-overview {
      margin-top: 40px;
      .data-title {
        font-size: 16px;
      }
    }
    .data-list {
      color: #999;
      .data-item {
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 0 10px;
        &:first-of-type {
          padding-bottom: 38px;
          border-bottom: 1px solid #ededed;
          margin-top: 28px;
        }
        &:nth-child(2){
          margin-top: 38px;
        }
        &:nth-child(3){
          margin-top: 32px;
          padding-bottom: 38px;
          border-bottom: 1px solid #ededed;
        }
        //&:nth-child(4){
        //  padding: 0;
        //  display: flex;
        //  align-items: center;
        //  margin-top: 34px;
        //  .item-num{
        //    margin-top: 0;
        //  }
        //  .item-right{
        //    display: inline-block;
        //    .item-num{
        //      background: #584EEE;
        //      color: #fff;
        //      border-radius: 10px;
        //      padding: 7px 11px;
        //      font-size: 12px;
        //      cursor: pointer;
        //    }
        //  }
        //}
        //&:last-of-type{
        //  //border-bottom: 1px solid #ededed;
        //  padding: 0 0 15px;
        //  margin-top: 28px;
        //  .item-left,.item-right{
        //    margin-top: 0;
        //  }
        //}
      }
      .item-left, .item-right{
        //width: 50%;
        //margin-top: 15px;
        display: flex;
        flex-direction: column;
        line-height: 1;
      }
      .item-num {
        font-size: 18px;
        color: #333;
        line-height: 1;
        margin-top: 10px;
      }
    }
    .bench-data-oper {
      margin-top: 40px;
      text-align: center;
    }

  }
  .bench-nodata-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    .bench-nodata-img {
      width: 200px;
      img {
        max-width: 100%;
      }
    }
    .bench-nodata-text {
      margin: 30px 0 40px;
      color: #666;
      font-size: 14px;
    }
  }
  .big-green {
    color: #20C997;
  }
}

</style>